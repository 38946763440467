import React from 'react';
import cuttingEdgeImage from './TranssenseIconLogo.png';
import TranssenseClip from './TranssenseClip.mov';
import Snaplogo from "./snaplogo.png"
import Arkhlogo from "./ARKH-letter-logo.svg"
import Smileylogo from "./smileylogo.png"
import Buildspacelogo from "./buildspacelogo.png"

const videoStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
};

const containerStyle = {
  display: 'flex', // Use flexbox to create a row layout
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: 'white',
};

const logoContainerStyle = {
  display: 'flex', // Use flexbox for the logo images
  justifyContent: 'space-between', // To add space between the images
  alignItems: 'center',
  width: '400px', // Set the width to control the overall spacing of the images
  marginBottom: '35px', // Add some margin to separate the logos from the text
};

const buildspaceLogoStyle = {
  width: '150px', // Adjust the width of the Buildspacelogo as needed
  marginTop: '-30px', // Move the Buildspacelogo 30 pixels higher
};

export default function Home() {
  const h2Style = {
    marginTop: '80px',
    color: 'transparent', // Set the text color to transparent
    margin: '20px', // Added 20px margin on each side
    padding: '10px', // Add padding to improve readability
    borderRadius: '5px', // Add border-radius for a nicer look
    position: 'relative', // Needed for proper layering of the gradient and text
    display: 'inline-block', // Ensure the div fits the text width
    background: 'linear-gradient(45deg, #FF4C4C, #41A1FF)', // Brighter red and blue gradient
    WebkitBackgroundClip: 'text', // Clip the background to the text
    backgroundClip: 'text', // Clip the background to the text
    textShadow: '2px 2px 4px rgba(255, 255, 255, 0.02)', // Reduced white text-shadow effect
  };
  return (
    <div>
      <video autoPlay loop muted style={videoStyle}>
        <source src={TranssenseClip} type="video/quicktime" />
      </video>
      <div style={containerStyle}>
        <img src={cuttingEdgeImage} alt="Logo" style={{ width: '200px' }} />
        <h1 style={{ marginTop: '20px' }}>Cutting Edge</h1>
        <h1 style={{ marginBottom: '20px' }}>Augmented Technologies</h1>
        <h1 style={{ ...h2Style, marginBottom: '25px' }}>Our Partners</h1>
        <div style={logoContainerStyle}>
          <img src={Snaplogo} style={{ width: '100px' }} />
          <img src={Arkhlogo} style={{ width: '150px' }} />
          <img src={Smileylogo} style={{ width: '90px' }} />
        </div>
        <img src={Buildspacelogo} alt="Buildspace" style={buildspaceLogoStyle} />
      </div>
    </div>
  );
}
