import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Contact from './pages/Contact';
import Home from './pages/Home';
import About from './pages/About';

function App() {
  useEffect(() => {
    document.title = 'Transsense'; // Change this to your desired title
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
