import React from 'react';
import TwitterLogo from "./twitterlogo.png"

const h2Style = {
  color: 'transparent', // Set the text color to transparent
  margin: '20px', // Added 20px margin on each side
  padding: '10px', // Add padding to improve readability
  borderRadius: '5px', // Add border-radius for a nicer look
  position: 'relative', // Needed for proper layering of the gradient and text
  background: 'linear-gradient(45deg, #F24B4B, #357EDD)', // Brighter red and blue gradient
  WebkitBackgroundClip: 'text', // Clip the background to the text
  backgroundClip: 'text', // Clip the background to the text
  textShadow: '2px 2px 4px rgba(255, 255, 255, 0.3)', // White text-shadow effect
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Add a subtle shadow for contrast
};

export default function Contact() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ ...h2Style, marginTop: '40px' }}>Reach out</h2>
      <img src="https://cdn.discordapp.com/attachments/801892786848268291/1133771110220038187/c0f1f4a28dcb2170b0273a037a6d854e_original.gif" style={{ width: '400px', borderRadius: '30px' }} />
      <h2 style={{ ...h2Style, marginBottom: '40px' }}>hello@transsense.ai</h2>
      <a href="https://twitter.com/transsenseai">
      <img src={TwitterLogo} style={{ width: '40px' }} />
      </a>
    </div>
  );
}
