export default function About() {
  const h2Style = {
    marginTop: '80px',
    color: 'transparent',
    margin: '20px',
    padding: '10px',
    borderRadius: '5px',
    position: 'relative',
    display: 'inline-block',
    background: 'linear-gradient(45deg, #F24B4B, #357EDD)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    textShadow: '2px 2px 4px rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
  };

  const gifStyle = {
    width: '350px', // Updated width to make all images the same size
    height: '250px', // Set a fixed height to maintain aspect ratio
    borderRadius: '25px',
    margin: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  };

  const paragraphStyle = {
    width: '300px', // Adjust the width of the paragraphs as needed
    marginLeft: '20px', // Add some space between the GIFs and paragraphs
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: '16px',
    lineHeight: '1.6',
  };

  const boldStyle = {
    fontWeight: 'bold', // Use this style to make the words before ";" bold
  };

  return (
    <div>
      <h2 style={h2Style}>
        Enhancing human perception and interaction by bridging the virtual with reality
      </h2>

      <div>
   
      <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133716803726606347/20200123082231-deeb9ac012d8917e0880b1887478a2ae.png"
          alt="GIF"
          style={gifStyle}
        />
        <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1131931243638960148/tSense.gif"
          alt="GIF"
          style={gifStyle}
        />
        <p style={paragraphStyle}>
          <span style={boldStyle}>Transsense Glove: </span> 
          An AI-powered smart glove with LiDARs, Camera, and Vibrators with the goal of
          making people with visual impairment autonomous. You can ask it to detect obstacles,
          find lost objects, and help you navigate around.
        </p>
      </div>

      <div>
      <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133746276421349518/19.11.2022_16.28.04_REC.png"
          alt="GIF"
          style={gifStyle}
        />
    
        <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1132439237040549959/gifwd.gif"
          alt="GIF"
          style={gifStyle}
        />
        <p style={paragraphStyle}>
          <span style={boldStyle}>Wand Duel:</span> A competitive spellcasting multiplayer game in augmented reality, built for Snapchat's
          AR glasses (Spectacles 2021). Using Snap's cutting-edge game 
          engine with advanced features as Speech Recognition, Gesture Control,
          Networking, and more. Featured at Snap Partner Summit and Lens Fest.
        </p>
      </div>

      <div>
      <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133743052121456752/a05152908d4bc7df083649c21d692261_original.jpg"
          alt="GIF"
          style={gifStyle}
        />
        <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133699541812641792/f1172cf33e8e5763d6e3c8c981a1dad4_original.gif"
          alt="GIF"
          style={gifStyle}
        />
        <p style={paragraphStyle}>
          <span style={boldStyle}>Collidor:</span> A particle smasher. Collidor is a next-generation board game infused with
          technology such as addressable LED lights and actuators. It's an innovative board game that combines physical and digital elements.
        </p>
      </div>

      <div>
      <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133718812752093264/uiikgvnfkrl4lp6t0ocs.jpg"
          alt="GIF"
          style={gifStyle}
        />
        <img
          src="https://cdn.discordapp.com/attachments/801892786848268291/1133704542811340800/idaclimb.gif"
          alt="GIF"
          style={gifStyle}
        />
        <p style={paragraphStyle}>
          <span style={boldStyle}>Hangpod:</span> A portable, collapsible, and triangular workout structure equipped with pull-up bars and grips. Quick assembly and modularity allow for easy setup and no height limitations. Made in lightweight aluminum which will support all your training and outdoor needs.
        </p>
      </div>

      <h2 style={h2Style}>
        We are using AI, computer vision, and wearable devices to build systems that benefits everyone
      </h2>
    </div>
  );
}
